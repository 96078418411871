import { Field } from './model';

const alwaysShow: string[] = [Field.UnitId, Field.UnitCode, Field.UnitName];
const unitInfoFields = alwaysShow.concat([Field.UnitTitle]);
const unitDescFields = alwaysShow.concat([Field.UnitTitle, Field.Description, Field.Description2]);
const parkingFields = alwaysShow.concat([Field.ParkingNotes, Field.ParkingTotalCars]);

export const fixSpecs: FixSpec[] = [
    {
        key: 'invalid-id',
        label: 'Required Unit Id',
        editFields: [Field.UnitId],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-code',
        label: 'Invalid Unit Code',
        editFields: [Field.UnitCode],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-name',
        label: 'Invalid Unit Name',
        editFields: [Field.UnitName],
        showFields: alwaysShow,
    },
    {
        key: 'invalid-title',
        label: 'Invalid Unit Title',
        editFields: [Field.UnitTitle],
        showFields: unitInfoFields,
    },
    {
        key: 'invalid-description',
        label: 'Invalid Description',
        editFields: [Field.Description],
        showFields: unitDescFields,
    },
    {
        key: 'invalid-description2',
        label: 'Invalid Description2',
        editFields: [Field.Description2],
        showFields: unitDescFields,
    },
    {
        key: 'invalid-notes',
        label: 'Invalid Unit Notes',
        editFields: [Field.UnitNotes],
        showFields: unitInfoFields.concat([Field.UnitNotes]),
    },
    {
        key: 'invalid-warning-email',
        label: 'Invalid Warning Email',
        editFields: [Field.WarningEmail],
        showFields: alwaysShow.concat([Field.WarningEmail]),
    },
    {
        key: 'invalid-parking-notes',
        label: 'Invalid Parking Notes',
        editFields: [Field.ParkingNotes],
        showFields: parkingFields,
    },
    {
        key: 'invalid-parking-total-cars',
        label: 'Invalid Parking Total Cars',
        editFields: [Field.ParkingTotalCars],
        showFields: parkingFields,
    },
    {
        key: 'invalid-driving-direction',
        label: 'Invalid Driving Direction',
        editFields: [Field.DrivingDirection],
        showFields: alwaysShow.concat([Field.DrivingDirection]),
    },
    {
        key: 'invalid-contract-terms',
        label: 'Invalid Contract Terms',
        editFields: [Field.ContractTerms],
        showFields: alwaysShow.concat([Field.ContractTerms]),
    },
    {
        key: 'invalid-unit-type',
        label: 'Invalid Unit Type',
        editFields: [Field.UnitType],
        showFields: alwaysShow.concat([Field.UnitType]),
    },
    {
        key: 'invalid-housekeeper-notes',
        label: 'Invalid Housekeeper notes',
        editFields: [Field.HousekeeperNotes],
        showFields: alwaysShow.concat([Field.HousekeeperNotes]),
    },
    {
        key: 'invalid-account-manager',
        label: 'Invalid Account Manager',
        editFields: [Field.AccountManager],
        showFields: alwaysShow.concat([Field.AccountManager]),
    },
    {
        key: 'invalid-local-operations-manager',
        label: 'Invalid Local Operations Manager',
        editFields: [Field.LocalOperationManager],
        showFields: alwaysShow.concat([Field.LocalOperationManager]),
    },
    {
        key: 'invalid-vacasa-contact',
        label: 'Invalid Vacasa Contact',
        editFields: [Field.VacasaContact],
        showFields: alwaysShow.concat([Field.VacasaContact]),
    },
    {
        key: 'invalid-standard-ad-headline',
        label: 'Invalid Standard ad headline',
        editFields: [Field.StandardAdHeadline],
        showFields: alwaysShow.concat([Field.StandardAdHeadline]),
    },
    {
        key: 'invalid-square-footage',
        label: 'Invalid Square Footage',
        editFields: [Field.SquareFootage],
        showFields: alwaysShow.concat([Field.SquareFootage]),
    },
];
