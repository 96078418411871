import { DataType } from '../util';
import {greaterThanZero, onlyAlphabetic, onlyEmail, onlyHousingType, onlyValidUnitTypes} from './parsers';
import { onlyEvenDoubleQuotationMarks } from '../util/parse-field';
import * as _ from 'lodash';

/**
 * Unique unit field names required by the Units Service API.
 */
export const Field = {
    AcquisitionId: 'acquisition',
    Bedrooms: 'bedrooms',
    HousingType: 'housing_type',
    MaxOccupancyTotal: 'max_occupancy',
    NotesForCopyWriters: 'notes_for_copywriters',
    LocalOperationsManager: 'local_operations_manager',
    VacasaContact: 'vacasa_contact_id',
    AccountManager: 'account_manager',
    SecuredBy: 'secured_by',
    UnitCode: 'code',
    // grouped in address object
    Address: 'address_1',
    Address2: 'address_2',
    City: 'city',
    State: 'state',
    ZipCode: 'zip',
    // grouped in bathrooms object
    FullBaths: 'full_baths',
    HalfBaths: 'half_baths',
    UnitType: 'unit_type_id',
    Id: 'id',
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Units/paths/~1v1~1units/post
 * @see https://docs.google.com/spreadsheets/d/1fs_noswYJqnzSHLXyQU67D0Zrb0tYWDTBAcLeQCIweU/edit?ts=5d1a9195#gid=91542833
 */
const unitModel: Model = [
    {
        name: Field.UnitCode,
        label: 'Unit Code',
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.Address,
        label: 'Address',
        type: DataType.Text,
        required: true,
        parse: onlyEvenDoubleQuotationMarks,
    },
    {
        name: Field.Address2,
        label: '2nd Address Line',
        type: DataType.Text,
        required: false,
        parse: onlyEvenDoubleQuotationMarks,
    },
    {
        name: Field.City,
        label: 'City',
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.State,
        label: 'State',
        type: DataType.Text,
        required: true,
        parse: onlyAlphabetic,
    },
    {
        name: Field.ZipCode,
        label: 'Zip',
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.HousingType,
        label: 'Housing Type',
        type: DataType.Text,
        required: true,
        parse: onlyHousingType,
    },
    {
        name: Field.SecuredBy,
        label: 'Secured By',
        type: DataType.Text,
        required: true,
        parse: onlyEmail,
    },
    {
        name: Field.NotesForCopyWriters,
        label: 'NotesForCopyWriters',
        type: DataType.Text,
        required: true,
    },
    {
        name: Field.MaxOccupancyTotal,
        label: 'Max Occupancy',
        type: DataType.PositiveInteger,
        required: true,
        parse: greaterThanZero,
    },
    {
        name: Field.Bedrooms,
        label: 'Bedrooms',
        type: DataType.Integer,
        required: true,
    },
    {
        name: Field.FullBaths,
        label: 'Full Baths',
        type: DataType.Integer,
        required: true,
    },
    {
        name: Field.HalfBaths,
        label: 'Half Baths',
        type: DataType.Integer,
        required: true,
    },
    {
        name: Field.LocalOperationsManager,
        label: 'Local Operations Manager',
        type: DataType.Text,
        required: false,
        parse: onlyEmail,
    },
    {
        name: Field.VacasaContact,
        label: 'Vacasa Contact',
        type: DataType.Text,
        required: false,
        parse: onlyEmail,
    },
    {
        name: Field.UnitType,
        label: 'Unit Type',
        type: DataType.Integer,
        required: true,
        parse: onlyValidUnitTypes,
    },
    {
        name: Field.AccountManager,
        label: 'Account Manager',
        type: DataType.Text,
        required: false,
        parse: onlyEmail,
    },
];

const unitModelExtraFields: Model = [
    {
        name: Field.Id,
        label: 'id',
        type: DataType.Integer,
    },
];
const fieldsToSuccessFile = ['code', 'id'];

const successModel = unitModel.concat(unitModelExtraFields).filter(m => {
    if (fieldsToSuccessFile.includes(m.name)) return m;
});

export const parseConfig: ParseConfig = {
    model: unitModel,
    skipRows: 2,
    keepZeros: true,
    successModel: successModel,
    async onRowParse(row, data) {
        if (!data.downloadErrors) {
            if (data.acqId === '0' || _.isNil(data.acqId)) {
                row._invalid.add(Field.AcquisitionId);
            } else {
                _.set(row, Field.AcquisitionId, data.acqId);
            }
        }

        row[Field.Bedrooms] = parseInt(row[Field.Bedrooms]);
        row[Field.UnitCode] = row[Field.UnitCode].trim();
        row[Field.FullBaths] = parseInt(row[Field.FullBaths]);
        row[Field.HalfBaths] = parseInt(row[Field.HalfBaths]);
        row[Field.Address] = row[Field.Address].trim();
        row[Field.Address2] = row[Field.Address2].trim() ?? null;
        row[Field.City] = row[Field.City].trim();
        row[Field.State] = row[Field.State].trim().toUpperCase();
        row[Field.ZipCode] = row[Field.ZipCode].trim().toUpperCase();
        row[Field.HousingType] = row[Field.HousingType].trim();
        row[Field.SecuredBy] = row[Field.SecuredBy].trim().toUpperCase();
        row[Field.NotesForCopyWriters] = row[Field.NotesForCopyWriters].trim();
        row[Field.LocalOperationsManager] = !_.isNil(row[Field.LocalOperationsManager])
            ? row[Field.LocalOperationsManager].trim().toUpperCase()
            : row[Field.LocalOperationsManager];
        row[Field.VacasaContact] = !_.isNil(row[Field.VacasaContact]) ? row[Field.VacasaContact].trim().toUpperCase() : row[Field.VacasaContact];
        row[Field.UnitType] = parseInt(row[Field.UnitType]);
        row[Field.AccountManager] = !_.isNil(row[Field.AccountManager]) ? row[Field.AccountManager].trim().toUpperCase() : row[Field.AccountManager];
    },
    async beforeDownload(rows: ParsedRow[]) {
        rows.forEach(r => {
            if (Object.keys(r._response).length > 0) {
                r[Field.Id] = r._response.data.id;
            }
        });
    },
};
