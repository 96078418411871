import { DataType } from '../util';
import { greaterThanZero, onlyValidUnitTypes } from './parsers';
import _ from 'lodash';
import {onlyEmail, onlyEmailOrNumber, onlyPositiveIntegerOrNull} from "../units/parsers";

/**
 * Unique unit field names required by the Unit API.
 */
export const Field = {
    UnitId: 'id',
    UnitCode: 'code',
    UnitName: 'name',
    UnitTitle: 'unit_title',
    Description: 'description',
    Description2: 'description2',
    UnitNotes: 'unit_notes',
    WarningEmail: 'warning_email',
    ParkingNotes: 'parking_notes',
    ParkingTotalCars: 'parking_total_cars',
    DrivingDirection: 'driving_directions',
    ContractTerms: 'contract_terms',
    UnitType: 'unit_type_id',
    HousekeeperNotes: 'housekeeper_notes',
    AccountManager: 'account_manager',
    LocalOperationManager: 'local_operations_manager',
    VacasaContact: 'vacasa_contact',
    StandardAdHeadline: 'standard_ad_headline',
    SquareFootage: 'unit_area',
};

/**
 * Define fields *in the order* they appear in CSV. The definitions must match
 * the Connect API:
 * @see https://connect.vacasa.com/#tag/Units/paths/~1v1~1units/post
 * @see https://docs.google.com/spreadsheets/d/1fs_noswYJqnzSHLXyQU67D0Zrb0tYWDTBAcLeQCIweU/edit?ts=5d1a9195#gid=91542833
 */
const unitModel: Model = [
    {
        name: Field.UnitId,
        label: 'Unit Id',
        type: DataType.Integer,
        required: true,
        parse: greaterThanZero,
    },
    {
        name: Field.UnitCode,
        label: 'Unit Code',
        type: DataType.Text,
        maxLength: 20,
    },
    {
        name: Field.UnitName,
        label: 'Name',
        type: DataType.Text,
        maxLength: 100,
    },
    {
        name: Field.UnitTitle,
        label: 'Title',
        type: DataType.Text,
        maxLength: 160,
    },
    {
        name: Field.Description,
        label: 'Description',
        type: DataType.Text,
        maxLength: 16000,
    },
    {
        name: Field.Description2,
        label: 'Description 2',
        type: DataType.Text,
        maxLength: 16000,
    },
    {
        name: Field.UnitNotes,
        label: 'Notes',
        type: DataType.Text,
    },
    {
        name: Field.WarningEmail,
        label: 'Warning Email',
        type: DataType.Text,
    },
    {
        name: Field.ParkingNotes,
        label: 'Parking Notes',
        type: DataType.Text,
        maxLength: 16000,
    },
    {
        name: Field.ParkingTotalCars,
        label: 'Parking Total Cars',
        type: DataType.Integer,
    },
    {
        name: Field.DrivingDirection,
        label: 'Driving direction',
        type: DataType.Text,
        maxLength: 16000,
    },
    {
        name: Field.ContractTerms,
        label: 'Contract Terms',
        type: DataType.Text,
        maxLength: 16000,
    },
    {
        name: Field.UnitType,
        label: 'Unit Type',
        type: DataType.Integer,
        required: true,
        parse: onlyValidUnitTypes,
    },
    {
        name: Field.HousekeeperNotes,
        label: 'Housekeeper Notes',
        type: DataType.Text,
    },
    {
        name: Field.AccountManager,
        label: 'Account Manager',
        type: DataType.Text,
        required: false,
        parse: onlyEmail
    },
    {
        name: Field.LocalOperationManager,
        label: 'Local Operation Manager',
        type: DataType.Text,
        required: false,
        parse: onlyEmailOrNumber
    },
    {
        name: Field.VacasaContact,
        label: 'Vacasa Contact',
        type: DataType.Text,
        required: false,
        parse: onlyEmailOrNumber
    },
    {
        name: Field.StandardAdHeadline,
        label: 'Standard Ad Site Headline',
        type: DataType.Text,
        maxLength: 50,
    },
    {
        name: Field.SquareFootage,
        label: 'Square Footage',
        type: DataType.Integer,
        parse: onlyPositiveIntegerOrNull
    },
];

export const parseConfig: ParseConfig = {
    model: unitModel,
    skipRows: 2,
    keepZeros: true,
    async onRowParse(row) {
        if (!_.isNil(row[Field.UnitTitle]) && row[Field.UnitTitle].length <= 3) row._invalid.add(Field.UnitTitle);
        if (!_.isNil(row[Field.UnitName]) && row[Field.UnitName].length <= 1) row._invalid.add(Field.UnitName);
        if (!_.isNil(row[Field.ParkingTotalCars]) && (row[Field.ParkingTotalCars] < 0 || row[Field.ParkingTotalCars] > 4294967295))
            row._invalid.add(Field.ParkingTotalCars);
        row[Field.UnitType] = parseInt(row[Field.UnitType]);
    },
};
