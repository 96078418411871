import React, { FunctionComponent } from 'react';
import { UploadView } from '../components';
import { parseConfig } from './model';
import { fixSpecs } from './fix-specs';
import { Role } from '../constants';
import { FilterArguments } from '../components/upload-view';
import * as _ from 'lodash';
import { formatStringNumber } from '../util/parse-field';

export const UnitsUpdateView: FunctionComponent = () => (
    <UploadView
        id="unit-update-upload-view"
        requiredRole={Role.UnitUpdate}
        label="Unit Update"
        apiPath="unit/units-update"
        uploadType="unit_update"
        parseConfig={parseConfig}
        fixSpecs={fixSpecs}
        isPlural={false}
        templateFlowEnabled={true}
        isConnectEntity={true}
        entityName="units"
        entityCSVModel={UnitUpdateCSVModel}
        customFilter={processCsvFile}
    />
);

/**
 * processes the response to load readable data into the template
 * @param input
 * @returns
 */
const processCsvFile = (input: FilterArguments) => {
    const { entities } = input;
    for (const entity of entities) {
        /**
         * Replace account manager id for email
         */
        const accountManagerEmail = _.get(entity, 'attributes.account_manager_email', "");
        _.set(entity, 'attributes.account_manager', accountManagerEmail);
    }
    return input;
};

const UnitUpdateCSVModel: Array<string> = [
    'code',
    'name',
    'unit_title',
    'description',
    'description2',
    'unit_notes',
    'warning_email',
    'parking_notes',
    'parking_total_cars',
    'driving_directions',
    'contract_terms',
    'unit_type_id',
    'housekeeper_notes',
    'account_manager',
    'local_operations_manager.email',
    'vacasa_contact.email',
    'standard_ad_headline',
    'unit_area.area', // AKA: Square Footage
];
